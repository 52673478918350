<script lang="ts" setup>
import { ToccoItem } from '~/api/drupal'

interface Props extends ToccoItem {
  dim?: boolean
}
const props = defineProps<Props>()

const isSeminar = computed(() => props.type === 'ToccoSeminar')
</script>

<template>
  <LinkListLargeItem :dim="!!dim" :link="link" :class="[{ seminar: isSeminar }]">
    <div class="title">
      <span class="label">{{ $t(`tocco.toccoTypes.${type}`) }}</span>
      <h4>{{ title }}</h4>
    </div>
    <div class="date">
      <span class="label"> {{ $t('tocco.teaser.labels.date') }}</span>
      <span class="text-small">{{ date }}</span>
    </div>
    <span v-if="teaser" class="teaser text-small f-bold">{{ teaser }}</span>
    <span v-else />
  </LinkListLargeItem>
</template>

<style lang="scss" scoped>
.item.item {
  padding-block: 30px;

  @include breakpoint(ts) {
    grid-template-columns: auto 1fr;
  }
  @include breakpoint(tl) {
    grid-template-columns: 7fr 1.5fr 2fr auto;
  }

  :deep(.button) {
    justify-self: end;
    @include breakpoint(ts) {
      grid-column: 2;
    }
    @include breakpoint(tl) {
      grid-column: auto;
    }
  }
}

.title {
  color: var(--c-primary);

  @include breakpoint(ts) {
    grid-column: 1 / span 2;
  }
  @include breakpoint(tl) {
    grid-column: 1 / span 1;
  }

  h4 {
    margin-bottom: 0;
  }
}

.date {
  display: flex;
  flex-direction: column;

  .seminar & {
    .label {
      display: none;
    }
  }
}

.teaser {
  justify-self: start;
  padding-inline: 8px;
  color: var(--c-primary);
  border-radius: 3px;
  border: 1px solid var(--c-blue-40);

  @include breakpoint(tl) {
    justify-self: end;
    padding-inline: 10px;
  }
}
</style>
